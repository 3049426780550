<template>
  <div class=" d-flex justify-content-center">
    <div class="card my-2 w-75">
      <div class="m-5 d-flex justify-content-center">
        <div class="mx-5 container ">
          <!--        <h5>Edit Specification Item</h5>-->
          <validation-observer
            ref="spec_item_form"
            tag="form"
          >
            <!--            <validation-provider-->
            <!--              #default="{ errors }"-->
            <!--              name="Spec Name"-->
            <!--              rules="required"-->
            <!--            >-->

            <!--              <label for="name">Spec name:</label>-->
            <!--              <b-form-input v-model="form.name" />-->
            <!--              <small class="text-danger">{{ errors[0] }}<br></small>-->
            <!--            </validation-provider>-->
            <label for="select">Select Budget Group</label>
            <v-select
              v-model="form.budget_group"
              :clearable="false"
              :options="budget_group"
              label="name"
              :reduce="(option) => option.key"
              disabled
              @input="clearSelects"
            />
            <br>
            <validation-provider
              #default="{ errors }"
              name="Element to attach"
              rules="required"
            >
              <label for="select">Attach to Element</label>
              <v-select
                v-model="selected_item"
                :clearable="false"
                :options="
                  form.budget_group === 'CNST'
                    ? cnst
                    : form.budget_group === 'FF&E'
                      ? ffe
                      : avit
                "
                :label="
                  form.budget_group === 'CNST' ? 'construction_spec' : 'element_name'
                "
              />
              <small
                class="text-muted"
              >This Spec will appear as spec swap.</small>
              <br>
              <small class="text-danger">{{ errors[0] }}<br></small>
            </validation-provider>
            <div
              v-if="selected_item!=null"
              class="row mt-1"
            >
              <div class="col-4" />
              <div class="col-4">
                <b> Base Spec</b>
              </div>
              <div class="col-4">
                <b>New Spec</b>
              </div>
              <div class="col-4">
                <b>Name</b>
              </div>
              <div class="col-4">
                <div class="ml-1">
                  <p>
                    {{ form.budget_group === 'CNST' ? selected_item.construction_spec : selected_item.element_name }}
                  </p>
                </div>
              </div>
              <div class="col-4 mt-0 pt-0">
                <validation-observer
                  ref="spec_item_form"
                  tag="form"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Spec Name"
                    rules="required"
                  >

                    <!--                  <label for="name">Spec name:</label>-->
                    <b-form-input v-model="form.name" />
                    <small class="text-danger">{{ errors[0] }}<br></small>
                  </validation-provider>
                </validation-observer>
              </div>
              <div
                v-if="form.budget_group !== 'CNST'"
                class="col-4"
              >
                <b>Manufacturer</b>
              </div>
              <div
                v-if="form.budget_group !== 'CNST'"
                class="col-4"
              >
                <div
                  v-if="form.budget_group !== 'CNST'"
                  class=""
                >
                  <p>
                    {{ selected_item.vendor }}
                  </p>
                </div>
              </div>
              <div
                v-if="form.budget_group !== 'CNST'"
                class="col-4 py-0 m-0 pb-1"
              >
                <div v-if="form.budget_group !== 'CNST'">
                  <b-form-input
                    v-model="form.manufacturer"
                    placeholder="[OPEN SPEC]"
                  />
                </div>
              </div>
              <div class="col-4">
                <b> Unit COGS</b>
              </div>
              <div class="col-4">
                <div
                  v-if="form.budget_group !== 'CNST'"
                  class="col"
                >
                  <p>
                    {{
                      Number(selected_item.unit_cost).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </p>
                </div>
                <div v-else>
                  <div class="col">
                    <p>
                      {{
                        Number(selected_item.unit_price).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-4 mt-0 pt-0">
                <div>
                  <validation-provider
                    #default="{ errors }"
                    name="Unit COGS"
                    rules="required"
                  >
                    <b-input-group prepend="$">
                      <b-form-input
                        v-model="form.cogs_per_unit"
                        placeholder="0"
                        type="number"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}<br></small>
                  </validation-provider>
                </div>
              </div>
              <div
                v-if="form.budget_group !== 'CNST'"
                class="col-4"
              >
                <b>Markup</b>
              </div>
              <div
                v-if="form.budget_group !== 'CNST'"
                class="col-4 "
              >
                <div class="ml-1">
                  {{ Number(((selected_item.supply_price-selected_item.unit_cost)/(selected_item.unit_cost))*100).toFixed(2) }} %
                </div>
              </div>
              <div
                v-if="form.budget_group !== 'CNST'"
                class="col-4 mt-0 pt-0"
              >
                <div v-if="form.budget_group !== 'CNST'">
                  <validation-provider
                    #default="{ errors }"
                    name="Markup"
                    :rules="{required:form.budget_group !== 'CNST'}"
                  >
                    <b-input-group append="%">
                      <b-form-input
                        v-model="form.markup"
                        placeholder="0"
                        type="number"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}<br></small>
                  </validation-provider>
                </div>

              </div>
              <div class="col-4">
                <b> Unit Price</b>
              </div>
              <div class="col-4">
                <div
                  v-if="form.budget_group !== 'CNST'"
                  class="col"
                >
                  <p>
                    {{
                      Number(selected_item.supply_price).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </p>
                </div>
                <div
                  v-else
                  class="col"
                >
                  <p>
                    {{
                      Number(selected_item.unit_price).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </p>
                </div>
              </div>
              <div
                class="col-4"
                :class="{'mt-0 pt-0':form.budget_group === 'CNST'}"
              >
                <div v-if="form.budget_group !== 'CNST'">
                  {{
                    Number(total_price).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
                <div v-else>
                  <validation-provider
                    #default="{ errors }"
                    name="Unit Price"
                    :rules="{required:form.budget_group === 'CNST'}"
                  >
                    <b-input-group prepend="$">
                      <b-form-input
                        v-model="form.price_per_unit"
                        type="number"
                        step="0.01"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}<br></small>
                  </validation-provider>

                </div>
              </div>
              <div
                v-if="form.budget_group !== 'CNST'"
                class="col-4"
              >
                <b> D&I</b>
              </div>
              <div
                v-if="form.budget_group !== 'CNST'"
                class="col-4"
              >
                <div
                  v-if="form.budget_group !== 'CNST'"
                  class="col"
                >
                  <p>
                    {{
                      Number(selected_item.unit_delivery_w_install).toLocaleString(
                        "en-CA",
                        {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        }
                      )
                    }}
                  </p>
                </div>
              </div>
              <div
                v-if="form.budget_group !== 'CNST'"
                class="col-4 pt-0"
              >
                <div v-if="form.budget_group !== 'CNST'">
                  <validation-provider
                    #default="{ errors }"
                    name="Delivery & Install"
                    :rules="{required:form.budget_group !== 'CNST'}"
                  >
                    <b-input-group append="%">
                      <b-form-input
                        v-model="form.unit_delivery_install"
                        placeholder="22.5"
                        type="number"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}<br></small>
                  </validation-provider>

                </div>
              </div>
              <div class="col-4">
                <b>Unit Price w/ D&I</b>
              </div>
              <div class="col-4">
                <div
                  v-if="form.budget_group !== 'CNST'"
                  class="col"
                >
                  <p>
                    {{
                      Number(selected_item.total_price).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </p>
                </div>
                <div
                  v-else
                  class="col"
                >
                  <p>
                    {{
                      Number(selected_item.unit_price).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </p>
                </div>
              </div>
              <div class="col-4">
                <div>
                  {{
                    Number(total_price_w_delivery).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
              </div>
              <div class="col-4">
                <b> Other Profit Unit</b>
              </div>
              <div class="col-4"/>
              <div class="col-4 mt-0 pt-0">
                <div>
                  <validation-provider
                      #default="{ errors }"
                      name="Unit COGS"
                      rules="required"
                  >
                    <b-input-group prepend="$">
                      <b-form-input
                          v-model="form.rebate_unit"
                          placeholder="0"
                          type="number"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}<br></small>
                  </validation-provider>
                </div>
              </div>
            </div>
          </validation-observer>
          <div class="w-100 my-2">
            <h5>Linked Spec Sets</h5>
            <div style="border: solid #f3f2f7 2px;">
              <div class="my-2 mx-1">
                <b-badge v-if="form.specification_swap_sets_names.length>0"
                  v-for="t,index in form.specification_swap_sets_names"
                  :key="index"
                  style="margin: 5px"
                  class="badge-mine"
                >{{ t }}
                </b-badge>
                <b-badge v-else>
                  Spec is not linked yet
                </b-badge>
              </div>
            </div>
          </div>
          <div class="text-right">
            <b-button
              class="my-2"
              variant="primary"
              @click="addSpecificationItem"
            >Save Spec
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BBadge, BButton, BCol, BFormInput, BInputGroup, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { UilGripHorizontalLine, UilTimes } from '@iconscout/vue-unicons'
import { required } from '@core/utils/validations/validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BFormInput,
    BButton,
    vSelect,
    BCol,
    BRow,
    UilTimes,
    UilGripHorizontalLine,
    BInputGroup,
    BBadge,
    required,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      show: false,
      step: 1,
      budget_group: [
        { name: 'Construction', key: 'CNST' },
        { name: 'FF&E', key: 'FF&E' },
        { name: 'AV/IT', key: 'AVIT' },
      ],
      specification_type: 'reuse',
      form: {
        budget_group: '',
        cogs_per_unit: '',
        construction_work_id: '',
        id: '',
        manufacturer: '',
        markup: '',
        name: '',
        price_per_unit: 0,
        uid: '',
        unit_delivery_install: 0,
        rebate_unit: 0,
      },
      emptyForm: {
        budget_group: 'CNST',
        cogs_per_unit: 0,
        construction_work_id: null,
        id: 1,
        manufacturer: null,
        markup: 10,
        name: null,
        price_per_unit: 0,
        specification_swap_set_id: 5,
        uid: null,
        unit_delivery_install: 22.5,
        rebate_unit: 0,
      },
      avit: [],
      cnst: [],
      ffe: [],
      selected_item: {},

    }
  },
  computed: {
    total_price() {
      return Number(
        Number(this.form.cogs_per_unit) * (1 + Number(this.form.markup) / 100),
      )
    },
    total_price_w_delivery() {
      if (this.form.budget_group != 'CNST') {
        return Number(this.total_price * (1 + (this.form.unit_delivery_install / 100)))
      }
      return this.form.price_per_unit
    },
  },
  mounted() {
    this.getBaseSpecItems()
    this.getSpeficationItem()
  },
  methods: {
    getBaseSpecItems() {
      this.$http
        .get('/specifications/sets/base-spec')
        .then(response => {
          const { data } = response
          this.avit = data.avit
          this.cnst = data.cnst
          this.ffe = data.ffe
        })
        .catch(error => {
          this.show = false
          this.showToast('danger', error.response.data.message)
        })
    },
    getSpeficationItem() {
      this.$http
        .get(`/specifications/items/${this.$route.params.id}`)
        .then(response => {
          const { data } = response
          this.form = data
          this.selected_item = this.form.budget_group === 'CNST' ? this.form.construction_work : this.form.element
          this.updateBreadcrumb()
        })
        .catch(error => {
          this.show = false
          this.showToast('danger', error.response.data.message)
        })
    },
    clearSelects() {
      this.selected_item = null
    },
    addSpecificationItem() {
      return new Promise((resolve, reject) => {
        this.$refs.spec_item_form.validate().then(success => {
          if (success) {
            if (this.form.budget_group !== 'CNST') {
              this.form.price_per_unit = this.total_price
              this.form.uid = this.selected_item.uid
              this.form.construction_work_id = null
            } else {
              this.form.construction_work_id = this.selected_item.id
              this.form.uid = null
              this.form.unit_delivery_install = 0
              this.form.markup = 0
            }
            this.$http
              .put(`/specifications/items/${this.$route.params.id}`, this.form)
              .then(() => {
                this.showToast('success', 'Spec saved')
                this.$router.push('/catalogue')
              })
              .catch(error => {
                this.show = false
                this.showToast('danger', error.response.data.message)
              })
          } else {
            reject()
          }
        })
      })
    },
    updateBreadcrumb() {
      this.$route.meta.breadcrumb[0].text = 'Spec Catalogue'
      this.$route.meta.breadcrumb[0].to = '/catalogue'

      this.$route.meta.breadcrumb[0].active = false
      this.$route.meta.breadcrumb[1].text = 'Spec'
      this.$root.$emit('rerender-breadcrumb')
    },
  },
}
</script>
<style lang="scss" scoped>
.badge-mine {
  color: #4d4d4d;
  background-color: #ebf6fc;
  font-size: 14px;
}
.col-4{
  margin-top: 15px;
}

</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
